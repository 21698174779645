import React, { useState, useEffect } from 'react';
import HeaderBgImg from '../images/header__background.png'
import '../App.css';
import { makeStyles } from '@material-ui/core';
import Footer from '../components/Footer';
import AppBarNone from '../components/AppBarNone';
import muiStyles from './muiStyle';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API } from '../hooks/api';
import { availableMonths, encode } from './helpper';
import clsx from 'clsx';
import Loading from '../components/Loading';

const useStyles = makeStyles(({
    container: {
        backgroundImage: `url(${HeaderBgImg})`,
        backgroundPosition: '0% 0%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        minHeight: '100vh',
    }
}));

const MyProfile = () => {
    const classes = muiStyles();
    const classes2 = useStyles();
    const [language, setLanguage] = useState('th');
    const [profile, setProfile] = useState();
    const [token, setToken] = useState();
    const [branchId, setBranchId] = useState();
    const [isShow, setShow] = useState(false);

    const [data, setData] = useState({});
    const [showEdit, setShowEdit] = useState(false);
    const [modelList, setModelList] = useState([]);
    const [loading, setLoading] = useState(false);

    const { isAuthenticated, login, isLogIn, getProfile,getAccessToken } = useLiff();
    
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const branchId = queryParams.get('branchId');
        setBranchId(branchId);

        if (!branchId) {
            alert(`ไม่พบหมายเลขสาขา กรุณาตรวจสอบลิงค์ของสาขาให้ถูกต้อง`);
            return
        }
        (async () => {
            if (await isLogIn()) {
                await setTokenProfile();
            } else {
                await liffLogin();
            }
        })();
    }, []);

    const setTokenProfile = async () => {
        const token = await getAccessToken();
        // let token = 'eyJhbGciOiJIUzI1NiJ9.tupecvdz0ZTrkcq_hZBrdoJbhvnh8pA45cSJHrT5jEl2nU8zDucCsU33CCgq8Un5ynSolUJ3ALKNsHjqX5JTThhI_9FBPEmVaSUy9l5IMo-NHDn7E02g4oxJSk3vddgbZG2ffiYc_0jqJltbR1NSL4jT1xTNwVaKVT1FNhgr1k0.a53Y7jrV0x2MlSJCxzpcljUfjBconCYoXvtGajIL-fQ'
        const profile = await getProfile();
        // profile.userId = 'U153da1cb24f2f88dfb9c76dc7262a1c9';
        setToken(token);
        setProfile(profile)
    }

    const liffLogin = async () => {
        await login();
        await setTokenProfile();
    }
    
    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            await getCheckProfile();
        })();
    }, [token, profile]);

    const getCheckProfile = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.CHECK_PROFILE, encodedData);
        if (response?.data?.detail === 'profile_existing') {
            setShow(true);
            (async () => {
                setShow(true);
                await getModel();
                await initMaster();
            })();
        }
        else {
            window.location = `/?branchId=${branchId}`
        }
    }

    const getModel = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.GET_MODEL, encodedData);
        setModelList(response?.data?.data||[]);
    }
    

    const initMaster = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.LOAD_PROFILE, encodedData);
        console.log("response", response);
        const data = response?.data?.profile;
        const dataProfile = {
            ...data,
            date: parseInt((data?.bhd||'').split('-')[2]),
            month: parseInt((data?.bhd||'').split('-')[1]), 
            year: parseInt((data?.bhd||'').split('-')[0]),
        }
        setData(dataProfile);
    }

    const [formData, setFormData] = useState({});
    const [formBlur, setFormBlur] = useState({});

    useEffect(() => {
        setFormData({
            ...data,
        })
    }, [showEdit])

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
        setFormBlur({
            ...formBlur,
            [name]: true
        })
    }

    const onBlur = (e) => {
        setFormBlur({
            ...formBlur,
            [e.target.name]: true
        })
    }

    const onChangeStatus = (e, value) => {
        setFormData({
            ...formData,
            vespaowner: value,
            enginenumber: value === 'true' ? formData.enginenumber : '',
        })
    }

    const onSubmit = () => {
        setFormBlur({
            mobile: true,
            postal: true,
            vespamodel: true,
            enginenumber: true
        })

        if (
            (formData.postal && formData.postal.length === 5 && !isNaN(formData.postal))
            && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
            && formData.vespamodel
        ) {
            var check = true;
            if (formData.vespaowner === 'true') {
                if (!formData.enginenumber || formData.enginenumber.length < 12 || (!/^[a-zA-Z0-9]+$/.test(formData.enginenumber))) {
                    check = false;
                }
            }

            if (check) {
                const param = {
                    accesstoken: token,
                    lineuserid: profile.userId,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    mobile: formData.mobile,
                    gender: data.gender,
                    bhd: data.bhd,
                    postal: formData.postal,            
                    vespaowner: formData.vespaowner === 'true' ? 1 : 0,
                    vespamodel: formData.vespamodel,
                    enginenumber: formData.enginenumber,
                    chassisnumber:"",
                    lat:"",
                    long:"",
                    lang: language
                }
                console.log("param", param);
                const encodedData = encode(param);
                setLoading(true);
                (async () => {
                    const response = await axios.post(API.UPDATE_USER, encodedData);
                    console.log("response", response);
                    if (response?.data?.result === "complete") {
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                        alert(response?.data?.detail || 'แก้ไขข้อมูลไม่สำเร็จ');
                    }

                    (async () => {
                        setShowEdit(false);
                        await initMaster();
                    })();
                })();
            }
        }
    }

    return (<>
    {!isShow ? (
        <AppBarNone />
    ) :
        <div className={classes2.container}>
            {loading && <Loading />}

            <div className="header_profile">
                <div className={classes.avatarFrame}>
                    <img className="avatar__image" src={profile?.pictureUrl} alt="" />
                </div>
                <div className={classes.language}>
                    <a
                        className={language === 'th' ? classes.languageAnchorActive: classes.languageAnchor}
                        onClick={e => setLanguage('th')}
                    >TH</a>
                    |
                    <a
                        className={language === 'en' ? classes.languageAnchorActive: classes.languageAnchor}
                        onClick={e => setLanguage('en')}
                    >EN</a>
                </div>
            </div>

            <form className={classes.form2}>
                <div className={classes.formItem}>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {language === 'en' ? 'First name' : 'ชื่อ'}
                        </label>
                    </div>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {data?.firstname}
                        </label>
                    </div>
                </div>

                <div className={classes.formItem}>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {language === 'en' ? 'Last name' : 'นามสกุล'}
                        </label>
                    </div>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {data?.lastname}
                        </label>
                    </div>
                </div>

                <div className={classes.formItem}>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {language === 'en' ? 'Gender' : 'เพศ'}
                        </label>
                    </div>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {language === 'en' ? 
                                (data.gender === 'male' ? 'Male' : 'Female') 
                                : (data.gender === 'male' ? 'ชาย' : 'หญิง')
                            }
                        </label>
                    </div>
                </div>

                <div className={classes.formItem}>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {language === 'en' ? 'Birth date' : 'วันเกิด'}
                        </label>
                    </div>
                    <div className={classes.formItem2}>
                        {data?.bhd && (
                            <label className={classes.formLabel}>
                                {language === 'en' ? (
                                    `${data?.date} ${availableMonths[data?.month - 1].en} ${data?.year}`
                                ) : `${data?.date} ${availableMonths[data?.month - 1].th} ${data?.year + 543}`}
                            </label>
                        )}
                    </div>
                </div>

                <div className={classes.formItem} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {language === 'en' ? 'Postcode' : 'รหัสไปรษณีย์'}
                        </label>
                    </div>
                    <div className={classes.formItem3}>
                        {showEdit ? (
                            <>
                            <div>
                                <input className={classes.formFieldInput} 
                                    placeholder={language === 'en' ? '5 digits number' : 'หมายเลข 5 หลัก'}
                                    type="text"
                                    name="postal"
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    maxLength={5}
                                    value={formData.postal}
                                />
                            </div>
                            {!formData.postal && formBlur.postal && (
                                <div className={classes.formFieldError}>
                                    {language === 'en' ? 'This field is required.' : 'กรุณากรอกข้อมูล'}
                                </div>
                            )}
                            {formData.postal && (
                                <>
                                {isNaN(formData.postal) ? (
                                    <div className={classes.formFieldError}>
                                        {language === 'en' ? 'Fill in numbers only.' : 'กรอกเป็นตัวเลขเท่านั้น'}
                                    </div>
                                ) : formData.postal.length < 5 ? (
                                    <div className={classes.formFieldError}>
                                        {language === 'en' ? '5 digit numbers.' : 'กรอกตัวเลข 5 หลัก'}
                                    </div>
                                ) : null}
                                </>
                            )}
                            </>
                        ):
                            <label className={classes.formLabel}>
                                {data?.postal}
                            </label>
                        }
                    </div>
                </div>

                <div className={classes.formItem} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {language === 'en' ? 'Mobile' : 'เบอร์โทรศัพท์'}
                        </label>
                    </div>
                    <div className={classes.formItem3}>
                        {showEdit ? (
                            <>
                            <div>
                                <input className={classes.formFieldInput} 
                                    placeholder={language === 'en' ? '5 digits number' : 'หมายเลข 10 หลัก'}
                                    type="text"
                                    name="mobile"
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    maxLength={10}
                                    value={formData.mobile}
                                />
                            </div>
                            {!formData.mobile && formBlur.mobile && (
                                <div className={classes.formFieldError}>
                                    {language === 'en' ? 'This field is required.' : 'กรุณากรอกข้อมูล'}
                                </div>
                            )}
                            {formData.mobile && (
                                <>
                                {isNaN(formData.mobile) ? (
                                    <div className={classes.formFieldError}>
                                        {language === 'en' ? 'Fill in numbers only.' : 'กรอกเป็นตัวเลขเท่านั้น'}
                                    </div>
                                ) : formData.mobile.length < 10 ? (
                                    <div className={classes.formFieldError}>
                                        {language === 'en' ? '10 digit numbers.' : 'กรอกตัวเลข 10 หลัก'}
                                    </div>
                                ) : null}
                                </>
                            )}
                            </>
                        ):
                            <label className={classes.formLabel}>
                                {data?.mobile}
                            </label>
                        }
                    </div>
                </div>

                <div className={classes.formItem} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {language === 'en' ? 'Have a Vespa?' : 'มี Vespa หรือไม่'}
                        </label>
                    </div>
                    <div className={classes.formItem3}>
                        {showEdit ? (
                            <>
                            <label className={classes.formFieldWrap} style={{ marginLeft: 0 }}>
                                <input
                                    className={classes.formFieldRadio}
                                    type="radio"
                                    name="vespaowner"
                                    value={formData?.vespaowner === 'true'}
                                    onChange={e => onChangeStatus(e, 'true')}
                                />
                                <div className={formData?.vespaowner === 'true'? clsx(classes.formRadio, classes.formRadioBgCheck) : clsx(classes.formRadio, classes.formRadioBg)}></div>
                                <span className={classes.formFieldTitle}>
                                    {language === 'en' ? 'Yes' : 'มี'}
                                </span>
                            </label>
                            <label className={classes.formFieldWrap}>
                                <input
                                    className={classes.formFieldRadio}
                                    type="radio"
                                    name="vespaowner"
                                    value={formData?.vespaowner === 'false'}
                                    onChange={e => onChangeStatus(e, 'false')}
                                />
                                <div className={formData?.vespaowner === 'false' ? clsx(classes.formRadio, classes.formRadioBgCheck) : clsx(classes.formRadio, classes.formRadioBg)}></div>
                                <span className={classes.formFieldTitle}>
                                    {language === 'en' ? 'No' : 'ไม่มี'}
                                </span>
                            </label>
                            </>
                        ):
                            <label className={classes.formLabel}>
                                {language === 'en' ? 
                                    (data.vespaowner === 'true' ? 'Yes' : 'No') 
                                    : (data.vespaowner === 'true' ? 'มี' : 'ไม่มี')
                                }
                            </label>
                        }
                    </div>
                </div>

                <div className={classes.formItem} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={classes.formItem2}>
                        <label className={classes.formLabel}>
                            {language === 'en' ? 'Have / interested' : 'มีรุ่น / สนใจรุ่น'}
                        </label>
                    </div>
                    <div className={classes.formItem3}>
                        {showEdit ? (
                            <>
                            <div>
                                <select
                                    className={formData.vespamodel ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                    name="vespamodel"
                                    value={formData.vespamodel}
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                >
                                    <option value="">เลือกรุ่น</option>
                                    {modelList.map(m => (
                                        <option value={m.titleth} key={m.itemid}>{m.titleth}</option>
                                    ))}
                                </select>
                            </div>
                            {!formData.vespamodel && formBlur.vespamodel && (
                                <div className={classes.formFieldError}>
                                    {language === 'en' ? 'This field is required.' : 'กรุณากรอกข้อมูล'}
                                </div>
                            )}
                            </>
                        ):
                            <label className={classes.formLabel}>
                                {data?.vespamodel}
                            </label>
                        }
                    </div>
                </div>

                {((data.vespaowner === 'true' && !showEdit) || (showEdit && formData.vespaowner === 'true')) && (
                    <div className={classes.formItem} style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.formItem2}>
                            <label className={classes.formLabel}>
                                {language === 'en' ? 'Engine number' : 'เลขเครื่องยนต์'}
                            </label>
                        </div>
                        <div className={classes.formItem3}>
                            {showEdit ? (
                            <>
                            <div>
                                <input className={classes.formFieldInput} 
                                    placeholder={language === 'en' ? 'Engine number' : 'เลขเครื่องยนต์'}
                                    type="text"
                                    name="enginenumber"
                                    onChange={onChangeInput}
                                    onBlur={onBlur}
                                    maxLength={12}
                                    value={formData.enginenumber}
                                />
                            </div>
                            {!formData.enginenumber && formBlur.enginenumber && (
                                <div className={classes.formFieldError}>
                                    {language === 'en' ? 'This field is required.' : 'กรุณากรอกข้อมูล'}
                                </div>
                            )}
                            {formData.enginenumber && (
                                <>
                                {!(/^[a-zA-Z0-9]+$/.test(formData.enginenumber)) ? (
                                    <div className={classes.formFieldError}>
                                        {language === 'en' ? 'Invalid Engine number.' : 'เลขเครื่องยนต์ไม่ถูกต้อง'}
                                    </div>
                                ) : formData.enginenumber.length < 12 ? (
                                    <div className={classes.formFieldError}>
                                        {language === 'en' ? '12 digit.' : 'ความยาว 12 หลัก'}
                                    </div>
                                ) : null}
                                </>
                            )}
                            </>
                        ):
                            <label className={classes.formLabel}>
                                {data?.enginenumber}
                            </label>
                        }
                        </div>
                    </div>
                )}

                <div className={classes.editor}>
                    {showEdit ? (
                        <a className={classes.editorRedeem}
                            onClick={onSubmit}
                        >
                            {language === 'en' ? 'Submit' : 'บันทึก'}
                        </a>
                    ) : 
                        <a className={classes.editorRedeem}
                            onClick={e => setShowEdit(true)}
                        >
                            {language === 'en' ? 'Edit Profile' : 'แก้ไขประวัติ'}
                        </a>
                    }
                </div>
            </form>
            
            <Footer imageIndex={1} />
        </div>
    }
    </>)
}

export default MyProfile;