import React from 'react';
import BrandImg from '../images/brand__image.png'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({
    header: {
        background: '#6accb2'
    },
    wrapper: {
        margin: 'auto',
        height: '100%'
    },
    image: {
        display: 'block',
        width: '100%',
        // maxHeight: 140,
    }
}));

const AppBar = () => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <div className={classes.wrapper}>
                <div>
                    <img
                        src={BrandImg}
                        className={classes.image}
                    />
                </div>
            </div>
        </div>
    )
}

export default AppBar;