import React, { useEffect, useState } from 'react';
import AppBar from '../components/AppBar';
import AppBarNone from '../components/AppBarNone';
import Loading from '../components/Loading';
import '../App.css';
import muiStyles from './muiStyle';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API } from '../hooks/api';
import { encode } from './helpper';

const PrivilegesDetail = () => {
    const classes = muiStyles();
    const [profile, setProfile] = useState();
    const [token, setToken] = useState();
    const [branchId, setBranchId] = useState();
    const [itemId, setItemId] = useState();
    const [loading, setLoading] = useState(false);

    const [isShow, setShow] = useState(false);

    const [data, setData] = useState({});
    const [termList, setTermList] = useState([]);

    const { isAuthenticated, login, isLogIn, getProfile,getAccessToken } = useLiff();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const branchId = queryParams.get('branchId');
        setBranchId(branchId);

        const itemId = queryParams.get('id');
        setItemId(itemId);

        if (!branchId) {
            alert(`ไม่พบหมายเลขสาขา กรุณาตรวจสอบลิงค์ของสาขาให้ถูกต้อง`);
            return
        }
        (async () => {
            if (await isLogIn()) {
                await setTokenProfile();
            } else {
                await liffLogin();
            }
        })();
    }, []);

    const setTokenProfile = async () => {
        const token = await getAccessToken();
        // let token = 'eyJhbGciOiJIUzI1NiJ9.id72UbrXeld_NTF1fjgnvE_KL5jYAJ2aCuGQi9GGm7MvbDDYyAE00d6CciCv79qtCI69X32tC0q1KxOAvvIuzDg7lAkaPwue-L1gFcrUtpOyuV20eqG2Pk2FxAAYR16v0kDLI1HLubSI0i4788anjFnu3t-gTTicTM3Gzf24TY8.2FXmsu69OvHGseoMFX1SbvPxW0YfDNsEwrDF1MbR2Tc'
        const profile = await getProfile();
        // profile.userId = 'U153da1cb24f2f88dfb9c76dc7262a1c9';
        setToken(token);
        setProfile(profile)
    }

    const liffLogin = async () => {
        await login();
        await setTokenProfile();
    }
    
    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            await getCheckProfile();
        })();
    }, [token, profile]);

    const getCheckProfile = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.CHECK_PROFILE, encodedData);
        if (response?.data?.detail === 'profile_existing') {
            setShow(true);
            (async () => {
                setShow(true);
                await initMaster();
            })();
        }
        else {
            window.location = `/?branchId=${branchId}`
        }
    }

    const initMaster = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'Reward',
        }
        const encodedData = encode(param);
        const coupons = await axios.post(API.LOAD_COUPON_DEALER, encodedData);

        const param2 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardCouponWithCond4',
        }
        const encodedData2 = encode(param2);
        const couponjan2021new1 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_NEW1, encodedData2);
        
        const param3 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardFillAddress11',
        }
        const encodedData3 = encode(param3);
        const couponjan2021new2 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_NEW2, encodedData3);
        
        const param4 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardCouponWithCond4C',
        }
        const encodedData4 = encode(param4);
        const couponjan2021forapr23_1 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_FOR_APR23, encodedData4);

        const param5 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardCouponWithCond5C',
        }
        const encodedData5 = encode(param5);
        const couponjan2021forapr23_2 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_FOR_APR23, encodedData5);

        const param6 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardFillAddress27C',
        }
        const encodedData6 = encode(param6);
        const couponjan2021forapr23_3 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_FOR_APR23, encodedData6);
        
        const param7 = {
            accesstoken: token,
            lineuserid: profile.userId,
            group: 'Vespa3Get1May2021',
        }
        const encodedData7 = encode(param7);
        const couponmay2021step1 = await axios.post(API.LOAD_COUPON_LIST_MAY_2021_STEP1, encodedData7);
        
        const items = [ 
            ...(coupons?.data?.items||[]),
            ...(couponjan2021new1?.data?.items||[]),
            ...(couponjan2021new2?.data?.items||[]),
            ...(couponjan2021forapr23_1?.data?.items||[]),
            ...(couponjan2021forapr23_2?.data?.items||[]),
            ...(couponjan2021forapr23_3?.data?.items||[]),
            ...(couponmay2021step1?.data?.items||[])
        ];
        const find = items.find(f => f.itemid === itemId);
        setData(find);
    }

    useEffect(() => {
        setTermList((data?.termth||'').split('!!'));
    }, [data])

    const pickUpCoupon = async () => {
        setLoading(true);
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
            itemid: itemId,
            // dealerid: branchId,
        }
        console.log("param", param);
        const encodedData = encode(param);
        const response = await axios.post(API.PICK_UP_COUPON, encodedData);
        console.log("response", response);
        if (response?.data?.result === "complete") {
            window.location = `/my-new-coupon?branchId=${branchId}`
        }
        else {
            setLoading(false);
            alert(response?.data?.detail || 'เกิดข้อผิดพลาดในการรับคูปอง');
        }
    }

    return (<>
    {!isShow ? (
        <AppBarNone />
    ) :
        <div className={classes.container}>
            <AppBar />

            <div className={classes.content}>
                <div className={classes.wrapper}>
                    <div className={classes.headingPath}>
                        <a href={`/privileges?branchId=${branchId}`} className={classes.headingBack}>
                        </a>
                        <div className={classes.headingText}>
                            {data?.titleth || ''}
                        </div>
                    </div>

                   <div className={classes.coupon}>
                        <div className={classes.coupons}>
                            <img
                                className="coupon__image"
                                src={data?.assetrectangle}
                            />

                            <div className={classes.remark}>
                                <div className={classes.remarkHeading}>
                                    เงื่อนไขการใช้คูปอง
                                </div>
                                <ul className={classes.remarkGroup}>
                                    {termList.map((m, i) => (
                                        <li className={classes.remarkList}>
                                            <span key={i}>{m}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                       </div>
                   </div>

                   <div className={classes.action}>
                       <a className={classes.actionRedeem} onClick={pickUpCoupon}>รับคูปอง</a>
                    </div>
                </div>
            </div>

            {loading && <Loading />}
        </div>
     }
     </>)
}

export default PrivilegesDetail;