import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import MenuProfileImg from '../images/menu--profile.jpg';
import MenuCouponImg from '../images/menu--coupon.jpg';
import MenuPrivilegeImg from '../images/menu--privilege.jpg';
import clsx from 'clsx';
import '../App.css';

const useStyles = makeStyles((theme) => ({
    footer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '300px',
        '@media (max-width: 768px)': {
            height: '230px',
        },
        '@media (max-width: 600px)': {
            height: '160px',
        },
        '@media (max-width: 500px)': {
            height: '110px',
        },
    },
    image1: {
        background: `url(${MenuProfileImg}) no-repeat 0 0/100%`
    },
    image2: {
        background: `url(${MenuCouponImg}) no-repeat 0 0/100%`
    },
    image3: {
        background: `url(${MenuPrivilegeImg}) no-repeat 0 0/100%`
    },
}));

const Footer = ({ imageIndex }) => {
    const classes = useStyles();
    const [branchId, setBranchId] = useState();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const branchId = queryParams.get('branchId');
        setBranchId(branchId);
    }, [])

    return (
        <div className={clsx(classes.footer, 
            imageIndex === 1 ? classes.image1 
            : imageIndex === 2 ? classes.image2 : classes.image3)}>
            <div className="menu" style={{ height: 240 }}>
                <div className="menu__list">
                    <div className="menu__item">
                        <a href={`/my-profile?branchId=${branchId}`} className="menu__anchor" />
                    </div>
                    <div className="menu__item">
                        <a href={`/my-new-coupon?branchId=${branchId}`} className="menu__anchor" />
                    </div>
                    <div className="menu__item">
                        <a href={`/privileges?branchId=${branchId}`} className="menu__anchor" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;