import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Privileges from './page/Privilleges';
import MyUsedCoupon from './page/MyUsedCoupon';
import MyProfile from './page/MyProfile';
import MyNewCoupon from './page/MyNewCoupon';
import MyNewCouponDetail from './page/MyNewCouponDetail';
import Index from './page';
import PrivilegesDetail from './page/PrivillegesDetail';
import { LiffProvider } from './contexts/LiffContext'
import { useEffect } from 'react';
import liff from '@line/liff'
import MyUsedCouponDetail from './page/MyUsedCouponDetail';
import { liffId } from './hooks/api';

function App() {
  console.log("Liff ID", liffId);
  
  useEffect(async () => {
    (async () => {
      await liff.init({
        liffId: liffId,
      })
    })()
  }, [])

  return (
    <Router>
      <LiffProvider>
        {/* <div className="App"> */}
        {/* <header className="App-header"> */}
        <Switch>
          <Route path="/" component={Index} exact={true} />
          <Route path="/privileges" component={Privileges} exact={true} />
          <Route path="/my-used-coupon" component={MyUsedCoupon} exact={true} />
          <Route path="/my-new-coupon" component={MyNewCoupon} exact={true} />
          <Route path="/my-profile" component={MyProfile} exact={true} />
          <Route path="/privileges-detail" component={PrivilegesDetail} exact={true} />
          <Route path="/coupon-detail" component={MyNewCouponDetail} exact={true} />
          <Route path="/my-used-coupon-detail" component={MyUsedCouponDetail} exact={true} />
        </Switch>
        {/* </header> */}
        {/* </div> */}
      </LiffProvider>
    </Router>
  );
}

export default App;
