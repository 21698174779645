import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
// import jwtDecode from 'jwt-decode';
// import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';

// import API from '../utils/api'
import liff from '@line/liff'
import { useState } from 'react';
import { liffId } from '../hooks/api';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }

//   const decoded = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;

//   return decoded.exp > currentTime;
// };

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      // const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        // user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      // const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        // user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const LiffContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  // register: () => Promise.resolve()
  getProfile: () => Promise.resolve(),
  isLogIn: () => Promise.resolve(),
  getAccessToken : () => Promise.resolve(),
});

export const LiffProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async () => {
    try {
   
      await liff.login();

      let token = await liff.getAccessToken();
      console.log("token", token);
      setSession(token);

      dispatch({
        type: 'LOGIN',
        // payload: {
        // }
      });

    } catch (err) {
      console.error(err);
      alert("Please update your LINE application");
      // window.location = "https://line.me/update";
    }

  };

  const isLogIn = async () => {
    const status =  await liff.isLoggedIn();
    console.log("status login : ", status);
    return status
  }

  const logout = () => {
    liff.logout();
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const getProfile = async () => {
    try {

      return await liff.getProfile()
    } catch (err) {
      console.error(err);
    }
  };

  const getAccessToken = async () => {
    try {
      return  await liff.getAccessToken();
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {

  }, []);

  return (
    <LiffContext.Provider
      value={{
        ...state,
        login,
        logout,
        getProfile,
        isLogIn,
        getAccessToken
      }}
    >
      {children}
    </LiffContext.Provider>
  );
};

export default LiffContext;