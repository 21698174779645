const developmentApiDomain = "https://linebcdev.vespiario-system.com/vespathai/api";
const productionApiDomain = "https://linebc.vespiario-system.com/vespathai/api";

export const liffId = "1654185770-JGRL1KpD";
// export const liffId = "1654106772-kYXY7YMJ";
// export const liffId = "1656441340-Lbpjb4wg";

const apiDomain = developmentApiDomain;

export const API = {
    CHECK_PROFILE: `${apiDomain}/profile/checkRegisterByUserId.aspx`,
    CREATE_NEW_USER: `${apiDomain}/profile/register.aspx`,
    UPDATE_USER: `${apiDomain}/profile/updateProfile.aspx`,
    GET_MODEL: `${apiDomain}/profile/getModel.aspx`,
    GET_BRANCH: `${apiDomain}/reward/getStoreAndService.aspx`,
    LOAD_MY_COUPON: `${apiDomain}/reward/loadMyCoupon.aspx`,
    // LOAD_COUPON_DEALER: `${apiDomain}/rewarddealer/loadCouponListDealer.aspx`,
    LOAD_COUPON_DEALER: `${apiDomain}/reward/loadCouponList.aspx`,
    LOAD_COUPON_LIST_JAN_2021_NEW1: `${apiDomain}/reward/loadCouponListJAN2021New1.aspx`,
    LOAD_COUPON_LIST_JAN_2021_NEW2: `${apiDomain}/reward/loadCouponListJAN2021New2.aspx`,
    LOAD_COUPON_LIST_JAN_2021_FOR_APR23: `${apiDomain}/reward/loadCouponListJAN2021ForAPR23.aspx`,
    LOAD_COUPON_LIST_MAY_2021_STEP1: `${apiDomain}/reward/loadCouponListMAY2021Step1.aspx`,
    PICK_UP_COUPON: `${apiDomain}/reward/pickupCoupon.aspx`,
    LOAD_PROFILE: `${apiDomain}/profile/loadDataProfile.aspx`,
    MY_INFO: `${apiDomain}/profile/myinfo.aspx`,
    REDEEM_COUPON: `${apiDomain}/reward/activateCoupon.aspx`,
    REDEEM_COUPON_FILL_ADDRESS: `${apiDomain}/reward/activateCouponFillAddress.aspx`,
    REDEEM_RANDOM_COUPON: `${apiDomain}/reward/activateCouponFillAddressCouponLimit.aspx`,
}