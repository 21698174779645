import React, { useEffect, useState } from 'react';
import AppBar from '../components/AppBar';
import AppBarNone from '../components/AppBarNone';
import Footer from '../components/Footer';
import '../App.css';
import muiStyles from './muiStyle';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API } from '../hooks/api';
import { availableMonths, encode, humanExpireTime } from './helpper';

const MyUsedCouponDetail = () => {
    const classes = muiStyles();
    const [profile, setProfile] = useState();
    const [token, setToken] = useState();
    const [branchId, setBranchId] = useState();
    const [itemId, setItemId] = useState();
    const [purchaseDate, setPurchaseDate] = useState();
    const [serviceDate, setServiceDate] = useState();

    const [isShow, setShow] = useState(false);

    const [data, setData] = useState({});

    const { isAuthenticated, login, isLogIn, getProfile, getAccessToken } = useLiff();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const branchId = queryParams.get('branchId');
        setBranchId(branchId);

        const itemId = queryParams.get('id');
        setItemId(itemId);

        if (!branchId) {
            alert(`ไม่พบหมายเลขสาขา กรุณาตรวจสอบลิงค์ของสาขาให้ถูกต้อง`);
            return
        }
        (async () => {
            if (await isLogIn()) {
                await setTokenProfile();
            } else {
                await liffLogin();
            }
        })();
    }, []);

    const setTokenProfile = async () => {
        const token = await getAccessToken();
        // let token = 'eyJhbGciOiJIUzI1NiJ9.U-NcEhTV-Qm19FjDEgykfTm5Ky7DDPlYXTc_2nZKWJiJ8FjGuRnVh391PxlXRUvISBhcQ_oHdERJD3Bj0dJQgET6nUGA3nwjgdFDAEyAIsdiWXlHkzoTltVeHtasNZY8LhxPaKerr9LAvlllnebRWFxOHDbVJd_Mqkf5eNbaiD4.AiP0Cco6um0mBwzKpvudXfN52Jz2Xt1SwFVuZhpZNY0'
        const profile = await getProfile();
        setToken(token);
        setProfile(profile)
    }

    const liffLogin = async () => {
        await login();
        await setTokenProfile();
    }
    
    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            await getCheckProfile();
        })();
    }, [token, profile]);

    const getCheckProfile = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.CHECK_PROFILE, encodedData);
        if (response?.data?.detail === 'profile_existing') {
            setShow(true);
            (async () => {
                setShow(true);
                await initMaster();
            })();
        }
        else {
            window.location = `/?branchId=${branchId}`
        }
    }

    const initMaster = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const coupons = await axios.post(API.LOAD_MY_COUPON, encodedData);
        const items = coupons?.data?.items||[];
        const find = items.find(f => f.itemid === itemId);
        console.log("coupon", find);
        setData(find);
    }

    useEffect(() => {
        if (data?.redeeminfo?.redeempurchase) {
            var redeemdate = data?.redeeminfo?.redeempurchase;
            var date = parseInt(redeemdate.split('-')[2]);
            var month = parseInt(redeemdate.split('-')[1]); 
            var year = parseInt(redeemdate.split('-')[0]);
            var dateFormat = `${date} ${availableMonths[month - 1].th} ${year}`;
            setPurchaseDate(dateFormat);
        }

        if (data?.redeeminfo?.redeemcouponserviceuse) {
            var redeemdate = data?.redeeminfo?.redeemcouponserviceuse;
            var date = parseInt(redeemdate.split('-')[2]);
            var month = parseInt(redeemdate.split('-')[1]); 
            var year = parseInt(redeemdate.split('-')[0]);
            var dateFormat = `${date} ${availableMonths[month - 1].th} ${year}`;
            setServiceDate(dateFormat);
        }
    }, [data])

    return (<>
    {!isShow ? (
        <AppBarNone />
    ) :
        <div className={classes.container}>
            <AppBar />

            <div className={classes.content}>
                <div className={classes.wrapper}>
                    <div className={classes.headingPath}>
                        <a href={`/my-used-coupon?branchId=${branchId}`} className={classes.headingBack}>
                        </a>
                        <div className={classes.headingText}>
                            {data?.titleth || ''}
                        </div>
                    </div>

                   <div className={classes.coupon}>
                        <div className={classes.coupons}>
                            <div className={classes.couponImageWrap}>
                                <img
                                    className="coupon__image coupon__anchor--expired"
                                    src={data?.assetrectangle}
                                />

                                <div className={classes.couponCodeWrap}>
                                    <div className={classes.couponCode}>
                                        {`No. ${data?.code || ''}`}
                                    </div>
                                </div>
                            </div>

                            {data?.activatedate !== '' && (
                                <div className={classes.couponFooter}>
                                    {`ใช้คูปองวันที่ ${humanExpireTime(data?.activatedate)}`}
                                </div>
                            )}

                            <div className={classes.redeem}>
                                {data?.redeeminfo?.redeemfirstname && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ชื่อ-นามสกุล
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeemfirstname} {data?.redeeminfo?.redeemlastname}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeemmobile && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            เบอร์โทรศัพท์
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeemmobile}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeembranchusage && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            โชว์รูม / สาขา / ศูนย์บริการ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeembranchusage}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeememail && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            อีเมล
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeememail}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeemaddress && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ที่อยู่
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeemaddress}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeemstreet && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ถนน
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeemstreet}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeemtumbon && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ตำบล / แขวง
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeemtumbon}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeemdistrict && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            อำเภอ / เขต
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeemdistrict}
                                        </div>
                                    </div>
                                )}

                                 {data?.redeeminfo?.redeemprovince && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            จังหวัด
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeemprovince}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeempostal && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            รหัสไปรษณีย์
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeempostal}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeembrandmodel && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            รุ่นรถ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeembrandmodel}
                                        </div>
                                    </div>
                                )}

                                {(data?.itemid === 'B16D9283-4267-4EB6-B172-7A88FAE6C16E' || data?.itemid === '9C617633-1BA5-48CD-A40C-4E2EB4D9D993') && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ของแถมเพิ่มเติม
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            PICNIC SET*,
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            ชุด Vespa Welcome Kit*,
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            ประกัน COVID-19*,
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            Vespa Home Delivery บริการจัดส่งรถถึงบ้าน
                                        </div>
                                    </div>
                                )}

                                {(data?.itemid === '8B559848-6601-447F-B6D1-D411B1C5C227') && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ของแถมเพิ่มเติม
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            &bull; Voucher 15,000 บาท<br />
                                            &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็คหน้า)<br />
                                            &bull; New Normal Pack<br />(หน้ากากผ้าและแอลกอฮอล์สเปรย์)<br />
                                            &bull; Vespa Welcome Kit<br />
                                            &bull; ประกันภัยชั้น 1 (1 ปี)
                                        </div>
                                    </div>
                                )}

                                {(data?.itemid === '4FEF0A9B-8E9E-4E1D-8189-5CDF830FAFDC' || data?.itemid === '98FBEF29-0218-4131-A4A9-CB4013DD8396') && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ของแถมเพิ่มเติม
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            ผ้ายางรองพื้นตามรุ่น,
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            Rainy Day Survival Kit
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            (เสื้อกันฝน และกระเป๋ากันน้ำ),
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            หมวกและพวงกุญแจ,
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            New Normal Pack
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            (หน้ากากผ้าและแอลกอฮอล์สเปรย์),
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            Vespa Welcome Kit,
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            ประกัน COVID-19
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeemenginenumber && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            เลขเครื่องยนต์
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeemenginenumber}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeemchassisnumber && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            เลขถังรถ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {data?.redeeminfo?.redeemchassisnumber}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeempurchase && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            วันที่ออกรถ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {purchaseDate}
                                        </div>
                                    </div>
                                )}

                                {data?.redeeminfo?.redeemcouponserviceuse && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            วันที่ใช้บริการ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {serviceDate}
                                        </div>
                                    </div>
                                )}
                            </div>
                       </div>
                   </div>
                </div>
            </div>

            <Footer imageIndex={2} />
        </div>
     }
     </>)
}

export default MyUsedCouponDetail;