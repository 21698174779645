import React, { useState, useEffect } from 'react';
import { encode, humanExpireTime } from './helpper';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import AppBarNone from '../components/AppBarNone';
import '../App.css';
import muiStyles from './muiStyle';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API } from '../hooks/api';

const Privileges = () => {
    const classes = muiStyles();
    const [profile, setProfile] = useState();
    const [token, setToken] = useState();
    const [branchId, setBranchId] = useState();
    const [isShow, setShow] = useState(false);

    const [privilegesList, setPrivilegesList] = useState([]);

    const { isAuthenticated, login, isLogIn, getProfile,getAccessToken } = useLiff();
    
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const branchId = queryParams.get('branchId');
        setBranchId(branchId);
        if (!branchId) {
            alert(`ไม่พบหมายเลขสาขา กรุณาตรวจสอบลิงค์ของสาขาให้ถูกต้อง`);
            return
        }
        alert("สิทธิพิเศษ");
        (async () => {
            if (await isLogIn()) {
                await setTokenProfile();
            } else {
                await liffLogin();
            }
        })();
    }, []);

    const setTokenProfile = async () => {
        const token = await getAccessToken();
        // let token = 'eyJhbGciOiJIUzI1NiJ9.U-NcEhTV-Qm19FjDEgykfTm5Ky7DDPlYXTc_2nZKWJiJ8FjGuRnVh391PxlXRUvISBhcQ_oHdERJD3Bj0dJQgET6nUGA3nwjgdFDAEyAIsdiWXlHkzoTltVeHtasNZY8LhxPaKerr9LAvlllnebRWFxOHDbVJd_Mqkf5eNbaiD4.AiP0Cco6um0mBwzKpvudXfN52Jz2Xt1SwFVuZhpZNY0'
        const profile = await getProfile();
        setToken(token);
        setProfile(profile)
    }

    const liffLogin = async () => {
        await login();
        await setTokenProfile();
    }
    
    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            await getCheckProfile();
        })();
    }, [token, profile]);

    const getCheckProfile = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.CHECK_PROFILE, encodedData);
        if (response?.data?.detail === 'profile_existing') {
            setShow(true);
            (async () => {
                setShow(true);
                await initMaster();
            })();
        }
        else {
            window.location = `/?branchId=${branchId}`
        }
    }

    const initMaster = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'Reward',
        }
        const encodedData = encode(param);
        const coupons = await axios.post(API.LOAD_COUPON_DEALER, encodedData);

        const param2 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardCouponWithCond4',
        }
        const encodedData2 = encode(param2);
        const couponjan2021new1 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_NEW1, encodedData2);
        
        const param3 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardFillAddress11',
        }
        const encodedData3 = encode(param3);
        const couponjan2021new2 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_NEW2, encodedData3);
        
        const param4 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardCouponWithCond4C',
        }
        const encodedData4 = encode(param4);
        const couponjan2021forapr23_1 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_FOR_APR23, encodedData4);

        const param5 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardCouponWithCond5C',
        }
        const encodedData5 = encode(param5);
        const couponjan2021forapr23_2 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_FOR_APR23, encodedData5);

        const param6 = {
            accesstoken: token,
            lineuserid: profile.userId,
            type: 'RewardFillAddress27C',
        }
        const encodedData6 = encode(param6);
        const couponjan2021forapr23_3 = await axios.post(API.LOAD_COUPON_LIST_JAN_2021_FOR_APR23, encodedData6);
        
        const param7 = {
            accesstoken: token,
            lineuserid: profile.userId,
            group: 'Vespa3Get1May2021',
        }
        const encodedData7 = encode(param7);
        const couponmay2021step1 = await axios.post(API.LOAD_COUPON_LIST_MAY_2021_STEP1, encodedData7);
        
        setPrivilegesList([ 
            ...(coupons?.data?.items||[]),
            ...(couponjan2021new1?.data?.items||[]),
            ...(couponjan2021new2?.data?.items||[]),
            ...(couponjan2021forapr23_1?.data?.items||[]),
            ...(couponjan2021forapr23_2?.data?.items||[]),
            ...(couponjan2021forapr23_3?.data?.items||[]),
            ...(couponmay2021step1?.data?.items||[])
        ]);
    }

    return (<>
    {!isShow ? (
        <AppBarNone />
    ) :
        <div className={classes.container}>
            <AppBar />

            <div className={classes.content}>
                <div className={classes.wrapper}>
                    <div className={classes.heading}>
                        สิทธิพิเศษ
                    </div>

                    {!privilegesList.length ? (
                        <div className={classes.coupon}>
                            <div className={classes.empty}>
                                <div className={classes.emptyMsg}>ไม่มีคูปอง</div>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.coupon}>
                            <div className={classes.coupons}>
                                {privilegesList.map((m, i) => {
                                    const expired = (m.isRunOut === "true" || m.isRedeemed === "true" || m.allowpickup === "false")
                                    return (
                                        <div className={classes.couponItem} key={i}>
                                            <a 
                                                className={`coupon__anchor ${expired ? 'coupon__anchor--expired' : ''}`}
                                                href={`/privileges-detail?branchId=${branchId}&id=${m.itemid}`}
                                            >
                                                <img
                                                    className="coupon__image"
                                                    src={m.assetsquarewithbutton}
                                                />
                                            </a>

                                            <div className={classes.couponFooter}>
                                                {m.expiretime === '' ? 'จนกว่าของพรีเมียมจะหมด' : `หมดเขต ${humanExpireTime(m.validend)}`}
                                            </div>

                                            {m.isRedeemed === "true" ? (
                                                <div className={classes.couponRunout}>
                                                    คุณได้ใช้สิทธิ์แล้ว
                                                </div>
                                            ) : m.allowpickup === "false" ? (
                                                <div className={classes.couponRunout}>
                                                    ไม่สามารถใช้สิทธิ์ได้
                                                </div>
                                            ) : m.isRunOut === "true" ? (
                                                <div className={classes.couponRunout}>
                                                    ผู้ใช้สิทธิ์ครบแล้ว
                                                </div>
                                            ) : null}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Footer imageIndex={3} />
        </div>
    }
    </>)
}

export default Privileges;