import React, { useState, useEffect } from 'react';
import { encode, humanExpireTime } from './helpper';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import AppBarNone from '../components/AppBarNone';
import clsx from 'clsx';
import muiStyles from './muiStyle';
import '../App.css';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API } from '../hooks/api';


const MyNewCoupon = () => {
    const classes = muiStyles();
    const [profile, setProfile] = useState();
    const [token, setToken] = useState();
    const [branchId, setBranchId] = useState();
    const [isShow, setShow] = useState(false);

    const [couponList, setCouponList] = useState([]);

    const { isAuthenticated, login, isLogIn, getProfile,getAccessToken } = useLiff();
    
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const branchId = queryParams.get('branchId');
        setBranchId(branchId);
        if (!branchId) {
            alert(`ไม่พบหมายเลขสาขา กรุณาตรวจสอบลิงค์ของสาขาให้ถูกต้อง`);
            return
        }
        (async () => {
            if (await isLogIn()) {
                await setTokenProfile();
            } else {
                await liffLogin();
            }
        })();
    }, []);

    const setTokenProfile = async () => {
        const token = await getAccessToken();
        // let token = 'eyJhbGciOiJIUzI1NiJ9.id72UbrXeld_NTF1fjgnvE_KL5jYAJ2aCuGQi9GGm7MvbDDYyAE00d6CciCv79qtCI69X32tC0q1KxOAvvIuzDg7lAkaPwue-L1gFcrUtpOyuV20eqG2Pk2FxAAYR16v0kDLI1HLubSI0i4788anjFnu3t-gTTicTM3Gzf24TY8.2FXmsu69OvHGseoMFX1SbvPxW0YfDNsEwrDF1MbR2Tc'
        const profile = await getProfile();
        setToken(token);
        setProfile(profile)
    }

    const liffLogin = async () => {
        await login();
        await setTokenProfile();
    }
    
    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            setShow(true);
            await initMaster();
        })();
    }, [token, profile]);

    const initMaster = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const coupons = await axios.post(API.LOAD_MY_COUPON, encodedData);
        setCouponList(coupons?.data?.items||[]);
    }

    return (<>
    {!isShow ? (
        <AppBarNone />
    ) :
        <div className={classes.container}>
            <AppBar />

            <div className={classes.content}>
                <div className={classes.wrapper}>
                    <div className={classes.heading}>
                        <div className={classes.headingTab}>
                            <a href={`/my-new-coupon?branchId=${branchId}`} className={clsx(classes.headingAnchor, classes.headingAnchorActive)}>
                                คูปองที่ใช้ได้
                            </a>
                            <a href={`/my-used-coupon?branchId=${branchId}`} className={classes.headingAnchor}>
                                คูปองที่ใช้แล้ว/หมดอายุ
                            </a>
                        </div>
                    </div>

                    {!couponList.length ? (
                        <div className={classes.coupon}>
                            <div className={classes.empty}>
                                <div className={classes.emptyMsg}>ไม่มีคูปอง</div>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.coupon}>
                            <div className={classes.coupons}>
                                {couponList.map((m, i) => {
                                    const expired = (m.activatedate !== '' || m.isExpired === "true" || m.isRunOut === "true" || m.isGroupUsed === "true")
                                    if (expired) {
                                        return null
                                    }
                                    return (
                                        <div className={classes.couponItem} key={i}>
                                            <a
                                                className={`coupon__anchor ${expired ? 'coupon__anchor--expired' : ''}`}
                                                href={`/coupon-detail?branchId=${branchId}&id=${m.itemid}`}
                                            >
                                                <img
                                                    className="coupon__image"
                                                    src={m.assetrectanglewithbutton}
                                                />
                                            </a>

                                            <div className={classes.couponCodeWrap}>
                                                <div className={classes.couponCode}>
                                                    {`No. ${m.code}`}
                                                </div>
                                            </div>

                                            <div className={classes.couponFooter}>
                                                {m.activatedate !== '' ? `ใช้คูปองวันที่ ${humanExpireTime(m.activatedate)}`
                                                    : m.isGroupUsed === 'true' ? 'ไม่สามารถใช้สิทธิ์ได้'
                                                        : m.expiretime === '' ? 'จนกว่าของพรีเมียมจะหมด'
                                                            : `หมดเขต ${humanExpireTime(m.expiretime)}`}
                                            </div>

                                            {m.isRunOut === "true" && (
                                                <div className={classes.couponRunout}>
                                                    ผู้ใช้สิทธิ์ครบแล้ว
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}

                </div>
            </div>

            <Footer imageIndex={2} />
        </div>
    }
    </>)
}

export default MyNewCoupon;