import React, { useEffect, useState } from 'react';
import AppBar from '../components/AppBar';
import AppBarNone from '../components/AppBarNone';
import Loading from '../components/Loading';
import '../App.css';
import '../css/awesomplete.css';
import muiStyles from './muiStyle';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API } from '../hooks/api';
import clsx from 'clsx';
import { availableMonths, encode, getDateList, getYearList, zeroPadding } from './helpper';
import moment from 'moment';

import Oct21Discount75Img from '../assets/oct21/discount75.jpg';
import Oct21Discount175Img from '../assets/oct21/discount175.jpg';
import ShowVoucherImg from '../assets/oct21/showVoucher.jpg';
import ShowVoucherNonImg from '../assets/oct21/showVoucherNon.jpg';
import VoucherGTS1Img from '../assets/oct21/voucherGTS1.jpg';
import VoucherGTS2Img from '../assets/oct21/voucherGTS2.jpg';
import VoucherGTS3Img from '../assets/oct21/voucherGTS3.jpg';
import VoucherGTS4Img from '../assets/oct21/voucherGTS4.jpg';
import VoucherGTS5Img from '../assets/oct21/voucherGTS5.jpg';
import VoucherAdd1Img from '../assets/oct21/voucherAddition1.jpg';
import VoucherAdd2Img from '../assets/oct21/voucherAddition2.jpg';
import VoucherAdd3Img from '../assets/oct21/voucherAddition3.jpg';
import VoucherAdd4Img from '../assets/oct21/voucherAddition4.jpg';

const init = {
    userInfo: null,
    firstname: "",  // ชื่อ
    lastname: "", // นามสกุล
    mobile: "", // เบอร์โทรศัพท์
    province: "",
    district: "",
    tumbon: "",
    postCode: "",
    addressDetail: "", // add
    reservationCode: "",
    model: "", // รุ่นรถ
    size: "", // ขนาดเครื่องยนต์
    manualSelectedModel: "",
    engineNumber: "", // เลขเครื่องยนต์
    bodyNumber: "", // เลขถังรถ
    purchasedDate: "",
    purchasedMonth: "",
    purchasedYear: 2021,

    serviceDate: "", // วันที่ใช้บริการ
    serviceMonth: "",
    serviceYear: 2021,

    couponValue: "", // มูลค่าคูปอง
    purchasedAmount: "",

    usageAmount: "", // มูลค่าที่ซื้อสินค้า
    serviceUsage: "",
    mileageCheckup: "",
    reward: "",

    additionalCost: "",

    couponDetail: "",
    couponTerm: [],
    branchList: [],
    targetBranch: "", // โชว์รูม/ศูนย์บริการ
    targetBranchId: "",

    sparepart: "", // อะไหล่

    isInputDirty: false,
    invalidBranch: false,
    isOverlayActive: false,
}

const MyNewCouponDetail = () => {
    const classes = muiStyles();
    const [profile, setProfile] = useState();
    const [token, setToken] = useState();
    const [branchId, setBranchId] = useState();
    const [itemId, setItemId] = useState();
    const [loading, setLoading] = useState(false);
    const [couponCode, setCouponCode] = useState();
    const [image, setImage] = useState();
    const [engineNumber, setEngineNumber] = useState();
    const [showVoucher, setShowVoucher] = useState(false);
    const [voucherSet, setVoucherSet] = useState();

    const [isShow, setShow] = useState(false);

    const [data, setData] = useState({});
    const [termList, setTermList] = useState([]);

    const { isAuthenticated, login, isLogIn, getProfile,getAccessToken } = useLiff();

    const [formData, setFormData] = useState({...init});
    const [formBlur, setFormBlur] = useState({});
    const [formType, setFormType] = useState();
    const [rewardType, setRewardType] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [notValidServiceDate, setNotValidServiceDate] = useState(false);
    const [notValidPurchaseDate, setNotValidPurchaseDate] = useState(false);

    const [modelList, setModelList] = useState([]); // รุ่นรถ
    const [branchList, setBranchList] = useState([]); // สาขา
    const [branchListAll, setBranchListAll] = useState([]); // สาขา
    const [modelListAll, setModelListAll] = useState([]); 
    const [sizeList, setSizeList] = useState([]); // ขนาดเครื่องยนต์
    const [usegeList, setUsageList] = useState([]); // มูลค่าที่ซื้อสินค้า
    const [dateList, setDateList] = useState([]); // วัน
    const [monthList, setMonthList] = useState([]); // เดือน
    const [yearList, setYearList] = useState([]); // ปี
    const [mileagList, setMileagList] = useState([]); // ตรวจเช็คระยะ
    const [sparepartList, setSparepartList] = useState([]); // อะไหล่

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const branchId = queryParams.get('branchId');
        setBranchId(branchId);

        const itemId = queryParams.get('id');
        setItemId(itemId);

        if (!branchId) {
            alert(`ไม่พบหมายเลขสาขา กรุณาตรวจสอบลิงค์ของสาขาให้ถูกต้อง`);
            return
        }
        (async () => {
            if (await isLogIn()) {
                await setTokenProfile();
            } else {
                await liffLogin();
            }
        })();
    }, []);

    const setTokenProfile = async () => {
        const token = await getAccessToken();
        // let token = 'eyJhbGciOiJIUzI1NiJ9.U-NcEhTV-Qm19FjDEgykfTm5Ky7DDPlYXTc_2nZKWJiJ8FjGuRnVh391PxlXRUvISBhcQ_oHdERJD3Bj0dJQgET6nUGA3nwjgdFDAEyAIsdiWXlHkzoTltVeHtasNZY8LhxPaKerr9LAvlllnebRWFxOHDbVJd_Mqkf5eNbaiD4.AiP0Cco6um0mBwzKpvudXfN52Jz2Xt1SwFVuZhpZNY0'
        const profile = await getProfile();
        // profile.userId = 'U153da1cb24f2f88dfb9c76dc7262a1c9';
        setToken(token);
        setToken(token);
        setProfile(profile)
    }

    const liffLogin = async () => {
        await login();
        await setTokenProfile();
    }
    
    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            await getCheckProfile();
        })();
    }, [token, profile]);

    const getCheckProfile = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.CHECK_PROFILE, encodedData);
        if (response?.data?.detail === 'profile_existing') {
            setShow(true);
            (async () => {
                setShow(true);
                await initInfo();
                await initMaster();
            })();
        }
        else {
            window.location = `/?branchId=${branchId}`
        }
    }

    const initInfo = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.MY_INFO, encodedData);
        const info = response?.data?.myinfo[0];
        setFormData({
            ...init,
            firstname: info?.firstname,
            lastname: info?.lastname,
            mobile: info?.mobile,
            // engineNumber: info?.enginenumber
        })
        setEngineNumber(info?.enginenumber || '');
    }

    const initMaster = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const coupons = await axios.post(API.LOAD_MY_COUPON, encodedData);
        const items = coupons?.data?.items||[];
        const find = items.find(f => f.itemid === itemId);
        console.log("coupon", find);
        setData(find);
    }

    useEffect(() => {
        setCouponCode(data?.code);
        setTermList((data?.termth||'').split('!!'));
    }, [data])

    /*** ---------------------- master dropdown ---------------------- ***/

    useEffect(() => {
        const type = data?.type;
        var formType = '';
        // if (type === 'formType20') {
        //     formType = 'formType1';
        // }
        if (type === 'RewardFillAddress2') {
            formType = 'formType2';
        }
        // else if (type === '') {
        //     formType = 'formType3';
        // }
        else if (type === 'RewardCouponWithCond4' || type === 'RewardCouponWithCond4C') {
            formType = 'formType4';
        }
        else if (type === 'RewardCouponWithCond5' || type === 'RewardCouponWithCond5C' || type === 'RewardCouponWithCond5B') {
            formType = 'formType5';
        }
        else if (type === 'RewardFillAddress11') {
            formType = 'formType6';
        }
        else if (type === 'RewardFillAddress12') {
            formType = 'formType7';
        }
        else if (type === 'RewardFillAddress13') {
            formType = 'formType8';
        }
        else if (type === 'RewardCouponWithCond6') {
            formType = 'formType9';
        }
        else if (type === 'RewardFillAddress14') {
            formType = 'formType10';
        }
        else if (type === 'RewardFillAddress27' || type === 'RewardFillAddress27C') {
            formType = 'formType11';
        }
        else if (type === 'RewardFillAddress28') {
            formType = 'formType12';
        }
        else if (type === 'Reward3Get1May2021-1') {
            formType = 'formType13';
        }
        else if (type === 'RewardFillAddress31') {
            formType = 'formType14';
        }
        else if (type === 'RewardFillAddress32') {
            formType = 'formType15';
        }
        else if (type === 'RewardCouponWithCond4A') {
            formType = 'formType16';
            // if (this.userInfo.enginenumber !== "") {
            //     this.engineNumber = this.userInfo.enginenumber;
            //     this.engineNumberInputHandler()
            // }
        }
        else if (type === 'RewardFillAddress33') {
            formType = 'formType17';
        }
        else if (type === 'RewardFillAddress34') {
            formType = 'formType18';
        }
        else if (type === 'RewardFillAddress35') {
            formType = 'formType19';
        }
        else if (type === 'RewardFillAddress36' || type === 'RewardFillAddress41' || type === 'RewardFillAddress44') {
            formType = 'formType20';
        }
        else if (type === 'RewardFillAddress38') {
            formType = 'formType21';
        }
        // else if (type === '') {
        //     formType = 'formType22';
        // }
        else if (type === 'RewardCouponWithCond7B') {
            formType = 'formType23';
        }
        else if (type === 'RewardFillAddress42' || type === 'RewardFillAddress45') {
            formType = 'formType24';
        }
        else if (type === 'RewardFillAddress46') {
            formType = 'formType25';
        }
        else if (type === 'RewardFillAddress47' || type === 'RewardFillAddress48') {
            formType = 'formType26';
        }
        else if (type === 'RewardFillAddress49') {
            formType = 'formType27';
        }

        // formType = 'formType25';
        console.log("Reward type:", type, formType);
        setFormType(formType);
        setRewardType(type);
    }, [data])

    useEffect(() => {
        if (formType) {
            if (
                formType === 'formType5' 
                || formType === 'formType20' 
                || formType === 'formType21'
                || formType === 'formType23'
                || formType === 'formType24'
                || formType === 'formType25'
                || formType === 'formType26'
                || formType === 'formType27'
            ) {
                (async () => {
                    await masterModel();
                })();
            }

            if (
                formType === 'formType5' 
                || formType === 'formType20'
                || formType === 'formType21'
                || formType === 'formType23'
                || formType === 'formType24'
                || formType === 'formType25'
                || formType === 'formType26'
                || formType === 'formType27'
            ) {
                (async () => {
                    await masterBranch();
                })();
            }

            if (
                formType === 'formType20' 
                || formType === 'formType24'
            ) {
                setUsageAmount();
            }

            if (formType === 'formType25') {
                setMileag();
            }

            if (formType === 'formType27') {
                setSparepart();
            }
            
            if (
                formType === 'formType21'
                || formType === 'formType23'
                || formType === 'formType24'
                // || formType === 'formType25'
            ) {
                setDateList(getDateList());
                setMonthList([{...availableMonths[8]}]);
                setYearList([{ th: 2564 , en: 2021 }]);
            }

            if (
                formType === 'formType5'
                || formType === 'formType25'
                || formType === 'formType26'
                || formType === 'formType27'
            ) {
                setDateList(getDateList());
                setMonthList([{...availableMonths[9]}]);
                setYearList([{ th: 2564 , en: 2021 }]);
            }

            if (formType === 'formType25' || formType === 'formType27') {
                setFormData({
                    ...formData,
                    engineNumber: engineNumber,
                })
            }
        }
    }, [formType])

    const masterModel = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.GET_MODEL, encodedData);
        setMasterModel(response?.data?.data || []);
    }

    const masterBranch = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
            branchtype: "store"
        }
        const encodedData = encode(param);
        const response = await axios.post(API.GET_BRANCH, encodedData);
        var list = response?.data?.data || [];
        var newlist = [...new Set(list.map(m => m.facadeth))]
        setBranchListAll(list);
        setBranchList(newlist);
    }

    const setMasterModel = (list) => {
        var item = [];
        // don't have 
        if (formType === 'formType2') {
            item =    [
                "LX 125 CC",
                "S 125 CC",
                "SPRINT 125 CC",
                "SPRINT 150 CC",
                "PRIMAVERA 150 CC",
                "GTS 150 CC",
                "GTS 300 CC",
                "GTV 300 CC",
            ]
        }
        else if (
            formType === 'formType20' 
            || formType === 'formType21'
            || formType === 'formType23'
            || formType === 'formType24'
            // || formType === 'formType25'
        ) {
            item = [
                "LX 125 CC",
                "LX 150 CC",
                "LXV 125 CC",
                "LXV 150 CC",
                "LT 125 CC",
                "S 125 CC",
                "SPRINT 125 CC",
                "SPRINT 150 CC",
                "PRIMAVERA 125 CC",
                "PRIMAVERA 150 CC",
                "PX 155 CC",
                "GTS 150 CC",
                "GTS 300 CC",
                "GTV 300 CC",
            ]
        }
        else if (
            formType === 'formType25'
        ) {
            item = [
                "LX 125 CC",
                "LX 150 CC",
                "LXV 125 CC",
                "LXV 150 CC",
                "LT 125 CC",
                "S 125 CC",
                "SPRINT 125 CC",
                "SPRINT 150 CC",
                "PRIMAVERA 125 CC",
                "PRIMAVERA 150 CC",
                "PX 155 CC",
                "GTS 150 CC",
                "GTS 300 CC",
                "SEI GIORNI II EDITION 300 CC",
                "946"
            ]
        }
        else if (formType === 'formType26') {
            if (rewardType === 'RewardFillAddress47') {
                item = [
                    "LX 125 CC",
                    "S 125 CC",
                    "SPRINT 125 CC",
                    "SPRINT 150 CC",
                    "SPRINT 150 CC RACING SIXTIES",
                    "SPRINT S 150 CC (TFT)",
                    "SPRINT 150 CC 10TH ANNI",
                    "PRIMAVERA 150 CC",
                    "PRIMAVERA S 150 CC",
                    "PRIMAVERA S 150 CC Touring",
                    "PRIMAVERA 150 CC SAFARI SE",
                    "PRIMAVERA 150 CC 75TH ANNI SE",
                    "PRIMAVERA 150 CC PIC NIC EDITION",
                    "PRIMAVERA RED",
                    "PRIMAVERA SEAN WOTHERSPOON",
                    "GTS SUPER 150 CC",
                ]
            }
            else if (rewardType === 'RewardFillAddress48') {
                item = [
                    "GTS SUPER SPORT 300 HPE",
                    "GTS SUPER TECH 300 HPE",
                    "GTS SUPER 300 CC Racing Sixties",
                    "GTS 75TH ANNI SE",
                    "SEI GIORNI II EDITION 300 HPE"
                ]
            }
        }
        else if (
            formType === 'formType27'
        ) {
            item = [
                "LX 125 CC",
                "LX 150 CC",
                "LXV 125 CC",
                "LXV 150 CC",
                "LT 125 CC",
                "S 125 CC",
                "SPRINT 125 CC",
                "SPRINT 150 CC",
                "PRIMAVERA 125 CC",
                "PRIMAVERA 150 CC",
                "PX 155 CC",
                "GTS 150 CC",
                "GTS 300 CC",
                "SEI GIORNI II EDITION 300 CC",
                "946"
            ]
        }

        console.log("list model", list);

        if (formType === 'formType5') {
            // var filter = [
            //     { titleen: 'GTS SUPER ABS * (MY 18)', titlesub1: 'GTS SUPER ABS (MY 18)', titlesub2: '300 CC' },
            //     { titleen: 'GTS SUPER SPORT * ABS (MY 19)', titlesub1: 'GTS SUPER SPORT ABS (MY 19)', titlesub2: '300 CC' }
            // ];
            var mapList = ['GTS SUPER 300 CC (MY 18)', 'GTS SUPER SPORT 300 CC (MY 19)'];
            // setModelListAll(filter);
            setModelList(mapList);
        }
        else if (formType === 'formType26') {
            var filter = list.filter(f => ~item.indexOf(f.titleen) < 0);
            var mapList = filter.map(f => f.titleen);
            console.log("mapList", mapList);
            setModelList(mapList);
        }
        else {
            var filter = list.filter(f => ~item.indexOf(f.titleen) < 0);
            setModelListAll(filter);
            var mapList = [...new Set(filter.map(m => m.titlesub1))];
            setModelList(mapList);
        }
    }

    useEffect(() => {
        setFormData({
            ...formData,
            size: ''
        })
        if (formData.model) {
            var filter = modelListAll.filter(f => f.titlesub1 === formData.model);
            var mapList = [...new Set(filter.map(m => m.titlesub2))];
            setSizeList(mapList);
        }
        else {
            setSizeList([]);
        }

        if (formData.model && formType === 'formType26') {
            if (rewardType === 'RewardFillAddress47') {
                if (~["SPRINT S 150 CC (TFT)", "PRIMAVERA 150 CC PIC NIC EDITION",
                    "PRIMAVERA RED", "PRIMAVERA SEAN WOTHERSPOON", "GTS SUPER 150 CC"
                ].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็คหน้า)<br />
                        &bull; New Normal Pack<br />(หน้ากากผ้าและแอลกอฮอล์สเปรย์)<br />
                        &bull; Vespa Welcome Kit
                    </>);
                    setImage(ShowVoucherNonImg);
                }
                else {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; Voucher 2,000 บาท<br />
                        &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็คหน้า)<br />
                        &bull; New Normal Pack<br />(หน้ากากผ้าและแอลกอฮอล์สเปรย์)<br />
                        &bull; Vespa Welcome Kit
                    </>);
                    setImage(ShowVoucherImg);
                }
            }
            else if (rewardType === 'RewardFillAddress48') {
                if (formData.model === 'GTS SUPER SPORT 300 HPE') {
                    setVoucherSet('set1');
                    setImage(VoucherGTS1Img);
                    setShowVoucher(<>
                        &bull; Voucher 2,000 บาท<br />
                        &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็คหน้า)<br />
                        &bull; New Normal Pack<br />(หน้ากากผ้าและแอลกอฮอล์สเปรย์)<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Vespa Accessories รุ่น GTS Super Sport 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER TECH 300 HPE') {
                    setVoucherSet('set2');
                    setImage(VoucherGTS2Img);
                    setShowVoucher(<>
                        &bull; Voucher 2,000 บาท<br />
                        &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็คหน้า)<br />
                        &bull; New Normal Pack<br />(หน้ากากผ้าและแอลกอฮอล์สเปรย์)<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Vespa Accessories รุ่น GTS Super Tech 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER 300 CC Racing Sixties') {
                    setVoucherSet('set3');
                    setImage(VoucherGTS3Img);
                    setShowVoucher(<>
                        &bull; Voucher 2,000 บาท<br />
                        &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็คหน้า)<br />
                        &bull; New Normal Pack<br />(หน้ากากผ้าและแอลกอฮอล์สเปรย์)<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Vespa Accessories รุ่น GTS Super 300 CC Racing Sixties
                    </>);
                }
                else if (formData.model === 'GTS 75TH ANNI SE') {
                    setVoucherSet('set4');
                    setImage(VoucherGTS4Img);
                    setShowVoucher(<>
                        &bull; Voucher 2,000 บาท<br />
                        &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็คหน้า)<br />
                        &bull; New Normal Pack<br />(หน้ากากผ้าและแอลกอฮอล์สเปรย์)<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Vespa Accessories รุ่น GTS 75th Anni SE
                    </>);
                }
                else if (formData.model === 'SEI GIORNI II EDITION 300 HPE') {
                    setVoucherSet('set5');
                    setImage(VoucherGTS5Img);
                    setShowVoucher(<>
                        &bull; Voucher 2,000 บาท<br />
                        &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็คหน้า)<br />
                        &bull; New Normal Pack<br />(หน้ากากผ้าและแอลกอฮอล์สเปรย์)<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Vespa Accessories รุ่น Sei Giorni II Edition 300 HPE
                    </>);
                }
            }
        }
    }, [formData.model])

    const setUsageAmount = () => {
        var list = [];
        if (formType === 'formType20') {
            list = [
                { key: 1, usageAmount: '3,000 - 6,999 บาท', couponValue: 'VOUCHER ส่วนลด 500 บาท' },
                { key: 2, usageAmount: '7,000 - 11,999 บาท', couponValue: 'VOUCHER ส่วนลด 1,500 บาท' },
                { key: 3, usageAmount: '12,000 บาท ขึ้นไป', couponValue: 'VOUCHER ส่วนลด 3,000 บาท' },
            ]
        }
        else if (formType === 'formType24') {
            list = [
                { key: 1, usageAmount: 'ยอดซื้อสินค้าอะไหล่ 946 บาทขึ้นไป', couponValue: 'VOUCHER ส่วนลด 200 บาท' },
                { key: 2, usageAmount: 'ยอดซื้อสินค้าอะไหล่ 1,946 บาทขึ้นไป', couponValue: 'VOUCHER ส่วนลด 500 บาท' },
            ]
        }
        setUsageList(list);
    }

    const setMileag = () => {
        var list = [];
        if (formType === 'formType25') {
            list = [
                { key: 1, value: "1,000 กม.", label: "1,000 กม.", couponValue: 'VOUCHER 75 บาท' },
                { key: 2, value: "5,000 กม.", label: "5,000 กม.", couponValue: 'VOUCHER 75 บาท' },
                { key: 3, value: "10,000 กม.", label: "10,000 กม.", couponValue: 'VOUCHER 175 บาท' },
                { key: 4, value: "15,000 กม.", label: "15,000 กม.", couponValue: 'VOUCHER 75 บาท' },
                { key: 5, value: "20,000 กม.", label: "20,000 กม.", couponValue: 'VOUCHER 175 บาท' },
                { key: 6, value: "25,000 กม.", label: "25,000 กม.", couponValue: 'VOUCHER 75 บาท' },
                { key: 7, value: "30,000 กม.", label: "30,000 กม.", couponValue: 'VOUCHER 175 บาท' },
            ]
        }
        setMileagList(list);
    }

    const setSparepart = () => {
        var list = [];
        if (formType === 'formType27') {
            list = [
                { key: 1, value: "สายพาน", couponValue: 'VOUCHER ส่วนลด 50%' },
                { key: 2, value: "ไส้กรองอากาศ", couponValue: 'VOUCHER ส่วนลด 50%' },
                { key: 3, value: "ไส้กรองน้ำมันเครื่อง", couponValue: 'VOUCHER ส่วนลด 50%' },
                { key: 4, value: "หัวเทียน", couponValue: 'VOUCHER ส่วนลด 50%' },
            ]
        }
        setSparepartList(list);
    }


    useEffect(() => {
        if (formData.usageAmount) {
            var find = usegeList.find(f => f.usageAmount === formData.usageAmount)
            setFormData({
                ...formData,
                couponValue: find?.couponValue || ''
            })
        }
        else {
            setFormData({
                ...formData,
                couponValue: ''
            })
        }
    }, [formData.usageAmount])

    useEffect(() => {
        if (formData.sparepart) {
            var find = sparepartList.find(f => f.value === formData.sparepart)
            setFormData({
                ...formData,
                couponValue: find?.couponValue || ''
            })

            if (formData.sparepart === 'สายพาน') {
                setImage(VoucherAdd1Img);
            }
            else if (formData.sparepart === 'ไส้กรองอากาศ') {
                setImage(VoucherAdd2Img);
            }
            else if (formData.sparepart === 'ไส้กรองน้ำมันเครื่อง') {
                setImage(VoucherAdd3Img);
            }
            else if (formData.sparepart === 'หัวเทียน') {
                setImage(VoucherAdd4Img);
            }
        }
        else {
            setFormData({
                ...formData,
                couponValue: ''
            })
        }
    }, [formData.sparepart])

    useEffect(() => {
        if (formData.mileageCheckup) {
            var find = mileagList.find(f => f.value === formData.mileageCheckup)
            setFormData({
                ...formData,
                couponValue: find?.couponValue || ''
            })
            
            if (find?.couponValue === 'VOUCHER 75 บาท') {
                setImage(Oct21Discount75Img);
                setVoucherSet('Voucher 75');
            }
            else {
                setImage(Oct21Discount175Img);
                setVoucherSet('Voucher 175');
            }
        }
        else {
            setFormData({
                ...formData,
                couponValue: ''
            })
        }
    }, [formData.mileageCheckup])

    /*** ---------------------- onchange ---------------------- ***/
    
    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
        setFormBlur({
            ...formBlur,
            [name]: true
        })
    }

    const onBlur = (e) => {
        setFormBlur({
            ...formBlur,
            [e.target.name]: true
        })
    }

    useEffect(() => {
        setIsSubmit(false);
        if (formType === 'formType1') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.addressDetail
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType3') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.addressDetail
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType5') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType20') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size 
                && formData.usageAmount && formData.couponValue
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType21') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType23') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType24') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size 
                && formData.usageAmount && formData.couponValue
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[a-zA-Z0-9]+$/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType25') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size 
                && formData.mileageCheckup && formData.couponValue
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[a-zA-Z0-9]+$/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType26') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType27') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size 
                && formData.sparepart && formData.couponValue
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[a-zA-Z0-9]+$/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
    }, [formData])

    useEffect(() => {
        if (formData.serviceDate && formData.serviceMonth && formData.serviceYear) {
            const serviceDate = `${zeroPadding(formData.serviceDate, 2)}/${zeroPadding(formData.serviceMonth, 2)}/${formData.serviceYear}`
            if (!moment(serviceDate, "DD/MM/YYYY", true).isValid()) {
                setNotValidServiceDate(true);
            }
            else {
                setNotValidServiceDate(false);
            }
        }
        else {
            setNotValidServiceDate(false);
        }
    }, [formData])

    useEffect(() => {
        if (formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear) {
            const purchasedDate = `${zeroPadding(formData.purchasedDate, 2)}/${zeroPadding(formData.purchasedMonth, 2)}/${formData.purchasedYear}`
            if (!moment(purchasedDate, "DD/MM/YYYY", true).isValid()) {
                setNotValidPurchaseDate(true);
            }
            else {
                setNotValidPurchaseDate(false);
            }
        }
        else {
            setNotValidPurchaseDate(false);
        }
    }, [formData])

    /*** ---------------------- submit ---------------------- ***/
    const usedCoupon = () => {

        if (formData.serviceDate && formData.serviceMonth && formData.serviceYear) {
            const serviceDate = `${zeroPadding(formData.serviceDate, 2)}/${zeroPadding(formData.serviceMonth, 2)}/${formData.serviceYear}`
            if (!moment(serviceDate, "DD/MM/YYYY", true).isValid()) {
                alert('วันที่ใช้บริการไม่ถูกต้อง');
            }
            else {
                setShowForm(true);
            }
        }
        else if (formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear) {
            const purchasedDate = `${zeroPadding(formData.purchasedDate, 2)}/${zeroPadding(formData.purchasedMonth, 2)}/${formData.purchasedYear}`
            if (!moment(purchasedDate, "DD/MM/YYYY", true).isValid()) {
                alert('วันที่ออกรถไม่ถูกต้อง');
            }
            else {
                setShowForm(true);
            }
        }
        else {
            setShowForm(true);
        }
    }

    const backToEdit = () => {
        setShowForm(false);
    }

    const onSubmit = async () => {
        // setLoading(true);

        var param = {
            accesstoken: token,
            lineuserid: profile.userId,
            // dealerid: branchId,
            code: couponCode,
            firstname: formData?.firstname,
            lastname: formData?.lastname,
            mobile: formData?.mobile,
        };
        var apiPath = '';

        var filterBranch = branchListAll.filter(f => f.facadeth === formData.targetBranch);
        var _branchId = "000";
        if (filterBranch.length) {
            _branchId = filterBranch[0].id
        }
        console.log(filterBranch, formData.targetBranch, _branchId);
        // console.log("find branch", branchListAll, formData.targetBranch, branchListAll.filter(f => f.facadeth === formData.targetBranch))

        if (formType === 'formType1') {
            apiPath = API.REDEEM_RANDOM_COUPON;
            param = {
                ...param,           
                enginenumber: formData?.engineNumber,
                address: formData?.addressDetail
            }
        }
        else if (formType === 'formType5') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                // brandmodel: formData.model === 'GTS SUPER ABS (MY 18)' ? 'GTS SUPER ABS 300 (MY 18)' : formData.model === 'GTS SUPER SPORT ABS (MY 19)' ? 'GTS SUPER SPORT 300 ABS (MY 19)' : '',
                // brandmodeldetail1: "GTS",
                // brandmodeldetail2: formData.size,
                brandmodel: formData.model,
                brandmodeldetail1: "GTS",
                brandmodeldetail2: "300 CC",
                enginenumber: formData.engineNumber,
                purchasedate: `${formData.purchasedYear}-${zeroPadding(formData.purchasedMonth, 2)}-${zeroPadding(formData.purchasedDate, 2)}`,
                param1: 'คูปอง',
                param1value: 'VOUCHER 15,000 บาท'
            }
        }
        else if (formType === 'formType20') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param1: "usage",
                param1value: formData.usageAmount,
                param2: "reward",
                param2value: formData.couponValue,
                enginenumber: formData.engineNumber,
            }
        }
        else if (formType === 'formType21') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: formData.model,
                enginenumber: formData.engineNumber,
                purchasedate: `${formData.purchasedYear}-${zeroPadding(formData.purchasedMonth, 2)}-${zeroPadding(formData.purchasedDate, 2)}`,
            }
        }
        else if (formType === 'formType23') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: formData.model,
                enginenumber: formData.engineNumber,
                purchasedate: `${formData.purchasedYear}-${zeroPadding(formData.purchasedMonth, 2)}-${zeroPadding(formData.purchasedDate, 2)}`,
            }
        }
        else if (formType === 'formType24') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param1: "usage",
                param1value: formData.usageAmount,
                param2: "reward",
                param2value: formData.couponValue,

                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate:  `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }
        else if (formType === 'formType25') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param1: "Voucher",
                param1value: voucherSet,
                param2: "Mileage",
                param2value: formData.mileageCheckup,

                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate:  `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }
        else if (formType === 'formType26') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: formData.model,
                enginenumber: formData.engineNumber,
                purchasedate: `${formData.purchasedYear}-${zeroPadding(formData.purchasedMonth, 2)}-${zeroPadding(formData.purchasedDate, 2)}`,
                param1: "package",
                param1value: voucherSet,
            }
        }
        else if (formType === 'formType27') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param1: "sparepart",
                param1value: formData.sparepart,

                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate:  `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }

        console.log("api", apiPath);
        console.log("param", param);

        const encodedData = encode(param);
        (async () => {
            const response = await axios.post(apiPath, encodedData);
            console.log("response", response);
            if (response?.data?.result === "complete") {
                // alert("complete!!");
                window.location = `/my-used-coupon?branchId=${branchId}`
            }
            else {
                setLoading(false);
                if (response?.data?.detail === 'FULLY_ACTIVATE') {
                    alert("ขออภัย ผู้ใช้สิทธิ์ครบแล้ว");
                }
                else if (response?.data?.detail === 'CouponCode_Expired') {
                    alert("ขออภัย คูปองหมดอายุแล้ว");
                }
                else {
                    alert(response?.data?.detail || 'ขออภัย ไม่สามารถกดใช้คูปองได้');
                }
            }
        })();
    }

    return (<>
    {!isShow ? (
        <AppBarNone />
    ) :
        <div className={classes.container}>
            <AppBar />

            <div className={classes.content}>
                <div className={classes.wrapper}>
                    <div className={classes.headingPath}>
                        <a href={`/my-new-coupon?branchId=${branchId}`} className={classes.headingBack}>
                        </a>
                        <div className={classes.headingText}>
                            {data?.titleth || ''}
                        </div>
                    </div>

                    {!showForm && (
                    <div className={classes.coupon}>
                        <div className={classes.coupons}>
                            <div className={classes.couponImageWrap}>
                                <img
                                    className="coupon__image"
                                    src={image || data?.assetrectangle}
                                />

                                <div className={classes.couponNumberWrap}>
                                    <div className={classes.couponNumber}>
                                        {`No. ${data?.code}`}
                                    </div>
                                </div>
                            </div>

                            <form className={classes.form3}>
                                {true && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            ชื่อ-นามสกุล
                                        </label>
                                        <div>
                                            <input className={classes.formFieldInput} 
                                                placeholder="ชื่อ-นามสกุล"
                                                type="text"
                                                name="firstName"
                                                value={formData?.firstname + " "  + formData?.lastname}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                )}

                                {true && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            เบอร์โทรศัพท์
                                        </label>
                                        <div>
                                            <input className={classes.formFieldInput} 
                                                placeholder="เบอร์โทรศัพท์"
                                                type="text"
                                                name="mobile"
                                                value={formData?.mobile}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                                maxLength={10}
                                            />
                                        </div>
                                        {!formData?.mobile && formBlur?.mobile && (
                                            <div className={classes.formFieldError}>กรุณากรอกข้อมูล</div>
                                        )}
                                        {formData?.mobile && (
                                            <>
                                            {isNaN(formData?.mobile) ? (
                                                <div className={classes.formFieldError}>กรอกเป็นตัวเลขเท่านั้น</div>
                                            ) : (formData?.mobile||'').length < 10 ? (
                                                <div className={classes.formFieldError}>กรอกตัวเลข 10 หลัก</div>
                                            ) : null}
                                            </>
                                        )}
                                    </div>
                                )}

                                {~['formType5', 'formType20', 'formType21', 
                                    'formType23', 'formType24', 'formType25', 
                                    'formType26', 'formType27'
                                ].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            โชว์รูม/ศูนย์บริการ
                                        </label>
                                        <div>
                                            <input
                                                className={classes.formFieldInput} 
                                                placeholder="ระบุสาขา"
                                                type="text"
                                                name="targetBranch"
                                                value={formData?.targetBranch}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                                list="branchlist"
                                            />
                                            {formData.targetBranch.length >= 3 && (
                                                <datalist id="branchlist">
                                                    {branchList.map(m => (
                                                        <option value={m} key={m}>{m}</option>
                                                    ))}
                                                </datalist>
                                            )}
                                        </div>
                                        {!formData?.targetBranch && formBlur?.targetBranch && (
                                            <div className={classes.formFieldError}>กรุณากรอกสาขา</div>
                                        )}
                                    </div>
                                )}

                                {~['formType5', 'formType20', 'formType21',
                                'formType23', 'formType24', 'formType25',
                                'formType26', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            รุ่นรถ
                                        </label>
                                        <div>
                                            <select
                                                className={formData.model ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                name="model"
                                                value={formData.model}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                            >
                                                <option value="">เลือกรุ่น</option>
                                                {modelList.map(m => (
                                                    <option value={m} key={m}>{m}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {!formData?.model && formBlur?.model && (
                                            <div className={classes.formFieldError}>กรุณาเลือกรุ่น</div>
                                        )}
                                    </div>
                                )}

                                {~['formType20', 'formType24', 
                                'formType25', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            ขนาดเครื่องยนต์
                                        </label>
                                        <div>
                                            <select
                                                className={formData.size ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                name="size"
                                                value={formData.size}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                            >
                                                <option value="">เลือกขนาด</option>
                                                {sizeList.map(m => (
                                                    <option value={m} key={m}>{m}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {!formData?.size && formBlur?.size && (
                                            <div className={classes.formFieldError}>กรุณาเลือกขนาด</div>
                                        )}
                                    </div>
                                )}

                                {~['formType20', 'formType24'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            {formType === 'formType20' ? 'มูลค่าที่ซื้อสินค้า'
                                             : formType === 'formType24' ? 'มูลค่าสินค้า'
                                             : 'มูลค่าที่ซื้อสินค้า'}
                                        </label>
                                        <div>
                                            <select
                                                className={formData.usageAmount ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                name="usageAmount"
                                                value={formData.usageAmount}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                            >
                                                <option value="">
                                                {formType === 'formType20' ? 'เลือกมูลค่า'
                                                : formType === 'formType24' ? 'เลือกยอดการใช้'
                                                : 'เลือกมูลค่า'}
                                                </option>
                                                {usegeList.map(m => (
                                                    <option value={m.usageAmount} key={m.key}>{m.usageAmount}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {!formData?.usageAmount && formBlur?.usageAmount && (
                                            <div className={classes.formFieldError}>กรุณาเลือกมูลค่า</div>
                                        )}
                                    </div>
                                )}

                                {~['formType25'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            ตรวจเช็คระยะ
                                        </label>
                                        <div>
                                            <select
                                                className={formData.mileageCheckup ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                name="mileageCheckup"
                                                value={formData.mileageCheckup}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                            >
                                                <option value="">
                                                    เลือกระยะ
                                                </option>
                                                {mileagList.map(m => (
                                                    <option value={m.value} key={m.key}>{m.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {!formData?.mileageCheckup && formBlur?.mileageCheckup && (
                                            <div className={classes.formFieldError}>กรุณาเลือกระยะ</div>
                                        )}
                                    </div>
                                )}

                                {~['formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            รายการอะไหล่
                                        </label>
                                        <div>
                                            <select
                                                className={formData.sparepart ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                name="sparepart"
                                                value={formData.sparepart}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                            >
                                                <option value="">
                                                    เลือกรายการอะไหล่
                                                </option>
                                                {sparepartList.map(m => (
                                                    <option value={m.value} key={m.key}>{m.value}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {!formData?.sparepart && formBlur?.sparepart && (
                                            <div className={classes.formFieldError}>กรุณาเลือกรายการอะไหล่</div>
                                        )}
                                    </div>
                                )}

                                {~['formType20', 'formType24', 'formType25', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            มูลค่าคูปอง
                                        </label>
                                        <div>
                                            <input className={classes.formFieldInput} 
                                                placeholder="ยังไม่ระบุ"
                                                type="text"
                                                name="couponValue"
                                                value={formData?.couponValue || ''}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                )}
                                
                                {~['formType5'].indexOf(formType) < 0 && formData.model && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            ของแถมเพิ่มเติม
                                        </label>
                                        <div className={classes.redeemDetail}>
                                            &bull; Voucher 15,000 บาท<br />
                                            &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็คหน้า)<br />
                                            &bull; New Normal Pack<br />(หน้ากากผ้าและแอลกอฮอล์สเปรย์)<br />
                                            &bull; Vespa Welcome Kit<br />
                                            &bull; ประกันภัยชั้น 1 (1 ปี)
                                        </div>
                                    </div>
                                )}

                                {~['formType25'].indexOf(formType) < 0 && formData.mileageCheckup && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            ของแถมเพิ่มเติม
                                        </label>
                                        <div className={classes.redeemDetail}>
                                            &bull; ฟรีบริการตรวจสภาพรถ 10 รายการ
                                        </div>
                                    </div>
                                )}

                                {~['formType26'].indexOf(formType) < 0 && formData.model && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            ของแถมเพิ่มเติม
                                        </label>
                                        <div className={classes.redeemDetail}>
                                            {showVoucher}
                                        </div>
                                    </div>
                                )}

                                {~['formType5', 'formType1', 'formType20', 
                                    'formType21', 'formType23', 'formType24', 
                                    'formType25', 'formType26', 'formType27'
                                ].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            เลขเครื่องยนต์
                                        </label>
                                        <div>
                                            <input className={classes.formFieldInput} 
                                                placeholder="เลขเครื่องยนต์"
                                                type="text"
                                                name="engineNumber"
                                                value={formData?.engineNumber}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                                maxLength={12}
                                            />
                                        </div>
                                        {!formData?.engineNumber && formBlur?.engineNumber && (
                                            <div className={classes.formFieldError}>กรุณากรอกเลขเครื่องยนต์</div>
                                        )}
                                        {formData?.engineNumber && (
                                            <>
                                            {!(/^[a-zA-Z0-9]+$/.test(formData?.engineNumber)) ? (
                                                <div className={classes.formFieldError}>เลขเครื่องยนต์ไม่ถูกต้อง</div>
                                            ) : formData?.engineNumber.length < 12 ? (
                                                <div className={classes.formFieldError}>ความยาว 12 หลัก</div>
                                            ) : null}
                                            </>
                                        )}
                                    </div>
                                )}

                                {~['formType24', 'formType25', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            เลขถังรถ
                                        </label>
                                        <div>
                                            <input className={classes.formFieldInput} 
                                                placeholder="เลขถังรถ"
                                                type="text"
                                                name="bodyNumber"
                                                value={formData?.bodyNumber}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                                maxLength={17}
                                            />
                                        </div>
                                        {!formData?.bodyNumber && formBlur?.bodyNumber && (
                                            <div className={classes.formFieldError}>กรุณากรอกเลขถังรถ</div>
                                        )}
                                        {formData?.bodyNumber && (
                                            <>
                                            {!(/^[a-zA-Z0-9]+$/.test(formData?.bodyNumber)) ? (
                                                <div className={classes.formFieldError}>เลขถังรถไม่ถูกต้อง</div>
                                            ) : formData?.bodyNumber.length < 17 ? (
                                                <div className={classes.formFieldError}>ความยาว 17 หลัก</div>
                                            ) : null}
                                            </>
                                        )}
                                    </div>
                                )}

                                {~['formType1', 'formType3'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            ที่อยู่
                                        </label>
                                        <div>
                                            <textarea 
                                                className={classes.formFieldTextArea} 
                                                placeholder="ที่อยู่"
                                                type="text"
                                                name="addressDetail"
                                                value={formData?.addressDetail}
                                                onChange={onChangeInput}
                                                onBlur={onBlur}
                                                rows={4}
                                            />
                                        </div>
                                        {!formData?.addressDetail && formBlur?.addressDetail && (
                                            <div className={classes.formFieldError}>กรุณากรอกที่อยู่</div>
                                        )}
                                    </div>
                                )}

                                {~['formType5', 'formType21', 'formType23',
                                 'formType26'
                                ].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            วันที่ออกรถ
                                        </label>
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <div className={classes.formBirthDayDate}>
                                                <select
                                                    className={formData.purchasedDate ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                    name="purchasedDate"
                                                    value={formData.purchasedDate}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                >
                                                    <option value="">วันที่</option>
                                                    {dateList.map(m => (
                                                        <option value={m} key={m}>{m}</option>
                                                    ))}
                                                </select>
                                                {!formData.purchasedDate && formBlur.purchasedDate && (
                                                    <div className={classes.formFieldError}>
                                                        กรุณาระบุวันที่
                                                    </div>
                                                )}
                                            </div>
                                            <div className={classes.formBirthDayMonth}>
                                                <select
                                                    className={formData.purchasedMonth ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                    name="purchasedMonth"
                                                    value={formData.purchasedMonth}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                >
                                                    <option value="">เดือน</option>
                                                    {monthList.map((m, i) => (
                                                        <option value={m.key} key={m.key}>{m.th}</option>
                                                    ))}
                                                </select>
                                                {!formData.purchasedMonth && formBlur.purchasedMonth && (
                                                    <div className={classes.formFieldError}>
                                                        กรุณาระบุเดือน
                                                    </div>
                                                )}
                                            </div>
                                            <div className={classes.formBirthDayYear}>
                                                <select
                                                    className={formData.purchasedYear ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                    name="purchasedYear"
                                                    value={formData.purchasedYear}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                >
                                                    <option value="">ปี</option>
                                                    {yearList.map(m => (
                                                        <option value={m.en} key={m.en}>{m.th}</option>
                                                    ))}
                                                </select>
                                                {!formData.purchasedYear && formBlur.purchasedYear && (
                                                    <div className={classes.formFieldError}>
                                                        กรุณาระบุปี
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {notValidPurchaseDate && (
                                            <div className={classes.formFieldError}>
                                                วันที่ออกรถไม่ถูกต้อง
                                            </div>
                                        )}
                                    </div>
                                )}

                                {~['formType24', 'formType25', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            วันที่ใช้บริการ
                                        </label>
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <div className={classes.formBirthDayDate}>
                                                <select
                                                    className={formData.serviceDate ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                    name="serviceDate"
                                                    value={formData.serviceDate}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                >
                                                    <option value="">วันที่</option>
                                                    {dateList.map(m => (
                                                        <option value={m} key={m}>{m}</option>
                                                    ))}
                                                </select>
                                                {!formData.serviceDate && formBlur.serviceDate && (
                                                    <div className={classes.formFieldError}>
                                                        กรุณาระบุวันที่
                                                    </div>
                                                )}
                                            </div>
                                            <div className={classes.formBirthDayMonth}>
                                                <select
                                                    className={formData.serviceMonth ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                    name="serviceMonth"
                                                    value={formData.serviceMonth}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                >
                                                    <option value="">เดือน</option>
                                                    {monthList.map((m, i) => (
                                                        <option value={m.key} key={m.key}>{m.th}</option>
                                                    ))}
                                                </select>
                                                {!formData.serviceMonth && formBlur.serviceMonth && (
                                                    <div className={classes.formFieldError}>
                                                        กรุณาระบุเดือน
                                                    </div>
                                                )}
                                            </div>
                                            <div className={classes.formBirthDayYear}>
                                                <select
                                                    className={formData.serviceYear ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)} 
                                                    name="serviceYear"
                                                    value={formData.serviceYear}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                >
                                                    <option value="">ปี</option>
                                                    {yearList.map(m => (
                                                        <option value={m.en} key={m.en}>{m.th}</option>
                                                    ))}
                                                </select>
                                                {!formData.serviceYear && formBlur.serviceYear && (
                                                    <div className={classes.formFieldError}>
                                                        กรุณาระบุปี
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {notValidServiceDate && (
                                            <div className={classes.formFieldError}>
                                                วันที่ใช้บริการไม่ถูกต้อง
                                            </div>
                                        )}
                                    </div>
                                )}
                            </form>

                            <div className={classes.remark}>
                                <div className={classes.remarkHeading}>
                                    เงื่อนไขการใช้คูปอง
                                </div>
                                <ul className={classes.remarkGroup}>
                                    {termList.map((m, i) => (
                                        <li className={classes.remarkList}>
                                            <span key={i}>{m}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                       </div>
                   </div>
                   )}

                   {showForm && (
                    <div className={classes.coupon}>
                        <div className={classes.coupons}>
                            <div className={classes.couponImageWrap}>
                                <img
                                    className="coupon__image"
                                    src={image || data?.assetrectangle}
                                />

                                <div className={classes.couponNumberWrap}>
                                    <div className={classes.couponNumber}>
                                        {`No. ${data?.code}`}
                                    </div>
                                </div>
                            </div>
                            
                            <div className={classes.redeem}>
                                <div className={classes.redeemBox}>
                                    <div className={classes.redeemHeader}>
                                        ชื่อ-นามสกุล
                                    </div>
                                    <div className={classes.redeemDetail}>
                                        {formData?.firstname} {formData?.lastname}
                                    </div>
                                </div>

                                <div className={classes.redeemBox}>
                                    <div className={classes.redeemHeader}>
                                        เบอร์โทรศัพท์
                                    </div>
                                    <div className={classes.redeemDetail}>
                                        {formData?.mobile}
                                    </div>
                                </div>

                                {~['formType5', 'formType20', 'formType21', 
                                'formType23', 'formType24', 'formType25',
                                'formType26', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            โชว์รูม/ศูนย์บริการ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.targetBranch}
                                        </div>
                                    </div>
                                )}

                                {~['formType5', 'formType20', 'formType21',
                                'formType23', 'formType24', 'formType25',
                                'formType26', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            รุ่นรถ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.model}
                                        </div>
                                    </div>
                                )}

                                {~['formType20', 'formType24', 'formType25', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ขนาดเครื่องยนต์
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.size}
                                        </div>
                                    </div>
                                )}

                                {~['formType20', 'formType24'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            {formType === 'formType20' ? 'มูลค่าที่ซื้อสินค้า'
                                             : formType === 'formType24' ? 'มูลค่าสินค้า'
                                             : 'มูลค่าที่ซื้อสินค้า'}
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.usageAmount}
                                        </div>
                                    </div>
                                )}

                                {~['formType25'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ตรวจเช็คระยะ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.mileageCheckup}
                                        </div>
                                    </div>
                                )}

                                {~['formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            รายการอะไหล่
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.sparepart}
                                        </div>
                                    </div>
                                )}

                                {~['formType20', 'formType24', 'formType25', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            มูลค่าคูปอง
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.couponValue}
                                        </div>
                                    </div>
                                )}

                                {~['formType5'].indexOf(formType) < 0 && formData.size && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            ของแถมเพิ่มเติม
                                        </label>
                                        <div className={classes.redeemDetail}>
                                            &bull; Voucher 15,000 บาท<br />
                                            &bull; Vespa Voyager Pack<br />
                                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์<br />
                                            &bull; Vespa Welcome Kit<br />
                                            &bull; ประกันภัยชั้น 1 (1 ปี)
                                        </div>
                                    </div>
                                )}

                                {~['formType25'].indexOf(formType) < 0 && formData.mileageCheckup && (
                                    <div className={classes.redeemBox}>
                                        <label className={classes.redeemHeader}>
                                            ของแถมเพิ่มเติม
                                        </label>
                                        <div className={classes.redeemDetail}>
                                            &bull; ฟรีบริการตรวจสภาพรถ 10 รายการ
                                        </div>
                                    </div>
                                )}

                                {~['formType26'].indexOf(formType) < 0 && formData.model && (
                                    <div className={classes.redeemBox}>
                                        <label className={classes.redeemHeader}>
                                            ของแถมเพิ่มเติม
                                        </label>
                                        <div className={classes.redeemDetail}>
                                            {showVoucher}
                                        </div>
                                    </div>
                                )}

                                {~['formType5', 'formType1', 'formType20', 
                                    'formType21', 'formType23', 'formType24', 
                                    'formType25', 'formType26', 'formType27'
                                ].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            เลขเครื่องยนต์
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.engineNumber}
                                        </div>
                                    </div>
                                )}

                                {~['formType24', 'formType25', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            เลขถังรถ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.bodyNumber}
                                        </div>
                                    </div>
                                )}

                                {~['formType5', 'formType21', 'formType23',
                                    'formType26'
                                ].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            วันที่ออกรถ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.purchasedDate} {availableMonths[parseInt(formData?.purchasedMonth) - 1]?.th} {formData?.purchasedYear}
                                        </div>
                                    </div>
                                )}

                                {~['formType24', 'formType25', 'formType27'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            วันที่ใช้บริการ
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.serviceDate} {availableMonths[parseInt(formData?.serviceMonth) - 1]?.th} {formData?.serviceYear}
                                        </div>
                                    </div>
                                )}

                                 {~['formType1', 'formType3'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            ที่อยู่
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.addressDetail}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className={classes.confirmRemark}>
                                <p className={classes.confirmParagraph}>
                                    {`หากข้อมูลถูกต้อง กรุณากด `}
                                    <span className={classes.confirmHilight}>'ยืนยันข้อมูล'</span>
                                    {` อีกครั้ง เพื่อบันทึกข้อมูลการใช้
                                    และเข้าไปที่หน้า คูปองที่ใช้แล้ว/หมดอายุ
                                    เพื่อแคปหน้าจอเป็นหลักฐาน`}
                                </p>
                                <p className={classes.confirmParagraph}>
                                    {`หากไม่กด `}
                                    <span className={classes.confirmUnderLine}>'ยืนยันข้อมูล'</span>
                                    {` จะถือว่าการใช้ไม่สมบูรณ์
                                    และสิทธิ์จะเป็นโมฆะทันที`}
                                </p>
                            </div>
                        </div>
                    </div>
                   )}

                    {showForm ? (
                        <div className={classes.action2}>
                            <button 
                                className={classes.actionButton}
                                onClick={backToEdit} 
                                style={{ display: 'inline-block' }}
                            >แก้ไขข้อมูล</button>
                            <button 
                                className={clsx(classes.actionButton, classes.actionSubmit)}
                                onClick={onSubmit} 
                                style={{ display: 'inline-block' }}
                            >ยืนยันข้อมูล</button>
                        </div>
                    ) : 
                        <div className={classes.action}>
                            <button 
                                className={isSubmit ? classes.actionRedeem : clsx(classes.actionRedeem, classes.actionRedeemDisabled)}
                                onClick={usedCoupon} 
                                disabled={!isSubmit}
                            >กดใช้คูปอง</button>
                        </div>
                    }
                </div>
            </div>

            {loading && <Loading />}
        </div>
     }
     </>)
}

export default MyNewCouponDetail;